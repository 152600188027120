import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

// Routes
// import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import example from './example'
import tagby from './tagby'

Vue.use(VueRouter)

const LABELLER_ACCEPTED_ROUTE = [
  'auth-login',
  'labeling-create',
  'auth-forgot-password',
  'auth-reset-password',
]

const guardedNext = (to, next, params) => {
  if (store.getters['verticalMenu/isLabellerMenu'] && !LABELLER_ACCEPTED_ROUTE.includes(to.name)) {
    return next({ name: 'labeling-create' })
  }
  if (params == null) {
    return next()
  }
  return next(params)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard' } },
    ...example,
    ...tagby,
    { path: '*', redirect: { name: 'error-404' } },
  ],
})

router.beforeEach(async (to, _, next) => {
  const isLoggedIn = await isUserLoggedIn()

  // 로그인된 상태에서 리다이렉트 하는 페이지(ex.로그인)
  if (to.meta.redirectIfLoggedIn) {
    // 로그인 되었으면
    if (isLoggedIn) {
      const userData = getUserData()
      return guardedNext(to, next, getHomeRouteForLoggedInUser(userData?.role))
    }
    // 로그인 되어있지 않으면
    return guardedNext(to, next)
  }

  // 로그인 안하면 안되는 페이지들에서 로그인 안되어있으면
  if (!isLoggedIn) {
    return guardedNext(to, next, { name: 'auth-login' })
  }

  return guardedNext(to, next)
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
