import campaign from "./campaign"
import commerce from "./commerce"

export default [
  {
    path: "/message/message-group",
    name: "message-group",
    component: () => import("@/views/message/message-group/MessageGroupList.vue"),
  },
  {
    path: "/message/sent-sms-list",
    name: "sent-sms-list",
    component: () => import("@/views/message/message-group/SentSMSList.vue"),
  },
  {
    path: "/message/notification-list",
    name: "notification-list",
    component: () => import("@/views/message/message-group/NotificationList.vue"),
  },
  {
    path: "/member/inf/list",
    name: "member-inf-list",
    component: () => import("@/views/member/inf/list/View.vue"),
  },
  {
    path: "/member/inf/view/:idx",
    name: "member-inf-view",
    component: () => import("@/views/member/influencer/detail/View.vue"),
  },
  {
    path: "/member/inf/detail/:idx",
    name: "member-inf-detail",
    component: () => import("@/views/member/inf/detail/View.vue"),
  },
  {
    path: "/member/inf/create/",
    name: "member-inf-create",
    component: () => import("@/views/member/influencer/create/View.vue"),
  },
  {
    path: "/member/adv/list",
    name: "member-adv-list",
    component: () => import("@/views/member/advertiser/list/View.vue"),
  },
  {
    path: "/member/adv/view/:idx",
    name: "member-adv-view",
    component: () => import("@/views/member/advertiser/detail/View.vue"),
  },
  {
    path: "/member/adm/list",
    name: "member-adm-list",
    component: () => import("@/views/member/AdminList.vue"),
  },
  {
    path: "/member/sns/list",
    name: "member-sns-list",
    // component: () => import('@/views/sns/SnsList.vue'),
    component: () => import("@/views/member/sns/list/View.vue"),
  },
  {
    path: "/pay/list",
    name: "pay-list",
    // component: () => import('@/views/pay/AdvPayList.vue'),
    component: () => import("@/views/pay/list/View.vue"),
  },
  {
    path: "/campaign/compt/list",
    name: "campaign-compt-list",
    component: () => import("@/views/campaign/compt/list/View.vue"),
  },
  {
    path: "/campaign/claim/list",
    name: "claim-list",
    component: () => import("@/views/campaign/ClaimList.vue"),
  },
  {
    path: "/campaign/claim/:idx",
    name: "claim-view",
    component: () => import("@/views/campaign/ClaimView.vue"),
    props: true,
  },
  {
    path: "/commerce/order/list",
    name: "commerce-order-list",
    component: () => import("@/views/commerce/OrderList.vue"),
  },
  {
    path: "/commerce/order/view/:idx",
    name: "commerce-order-view",
    component: () => import("@/views/commerce/OrderView.vue"),
  },
  {
    path: "/commerce/store/adjust",
    name: "commerce-store-adjust",
    component: () => import("@/views/commerce/StoreAdjust.vue"),
  },
  {
    path: "/commerce/mystore/list",
    name: "commerce-mystore-list",
    component: () => import("@/views/commerce/MystoreList.vue"),
  },
  {
    path: "/commerce/mystore/create",
    name: "commerce-mystore-create",
    component: () => import("@/views/commerce/MystoreCreateView.vue"),
  },
  {
    path: "/commerce/mystore/:idx",
    name: "commerce-mystore-view",
    component: () => import("@/views/commerce/MystoreView.vue"),
    props: true,
  },
  {
    path: "/commerce/legacy/product/list",
    name: "commerce-legacy-product-list",
    component: () => import("@/views/commerce/ProductList.vue"),
  },
  {
    path: "/commerce/product/list",
    name: "commerce-product-list",
    component: () => import("@/views/commerce/product/list/View.vue"),
  },
  {
    path: "/commerce/product/create",
    name: "commerce-product-create",
    component: () => import("@/views/commerce/ProductCreateView.vue"),
  },
  {
    path: "/commerce/legacy/product/:idx",
    name: "commerce-product-view",
    component: () => import("@/views/commerce/ProductView.vue"),
    props: true,
  },
  {
    path: "/commerce/product/:idx",
    name: "commerce-product-detail",
    component: () => import("@/views/commerce/product/detail/View.vue"),
    props: true,
  },
  {
    path: "/commerce/product/request/:idx",
    name: "commerce-product-request-view",
    component: () => import("@/views/commerce/ProductRequestView.vue"),
    props: true,
  },
  {
    path: "/commerce/product-update/list",
    name: "commerce-product-update-list",
    component: () => import("@/views/commerce/product_update/list/View.vue"),
  },
  {
    path: "/commerce/product-update/update-detail/:idx",
    name: "commerce-product-update-detail",
    component: () => import("@/views/commerce/product_update/update-detail/View.vue"),
    props: true,
  },
  {
    path: "/commerce/product-update/others-detail/:idx",
    name: "commerce-product-update-others-detail",
    component: () => import("@/views/commerce/product_update/others-detail/View.vue"),
    props: true,
  },
  {
    path: "/point/exchange/list",
    name: "point-exchange-list",
    component: () => import("@/views/point/exchange/list/ExchangeListView.vue"),
  },
  {
    path: "/point/inf-adjust-exchange/list",
    name: "inf-adjust-exchange-list",
    component: () => import("@/views/point/inf_adjust/list/ListView.vue"),
  },
  {
    path: "/point/point/list",
    name: "point-point-list",
    component: () => import("@/views/point/point/list/View.vue"),
  },
  {
    path: "/message/message-group/list",
    name: "message-group-list",
    component: () => import("@/views/message/message-group/MessageGroupList.vue"),
  },
  {
    path: "/update-content/list",
    name: "update-content-list",
    component: () => import("@/views/update-content/list/View.vue"),
  },
  {
    path: "/update-content/detail/:idx",
    name: "update-content-detail",
    component: () => import("@/views/update-content/detail/View.vue"),
  },
  {
    path: "/update-content/create",
    name: "update-content-create",
    component: () => import("@/views/update-content/create/View.vue"),
  },
  {
    path: "/tracking-code/list",
    name: "tracking-code-list",
    component: () => import("@/views/tracking-code/list/View.vue"),
  },
  {
    path: "/brand-sns/list",
    name: "brand-sns-list",
    component: () => import("@/views/brand-sns/list/View.vue"),
  },
  {
    path: "/brand-sns/create",
    name: "brand-sns-create",
    component: () => import("@/views/brand-sns/create/View.vue"),
  },
  {
    path: "/brand-sns/detail/:idx",
    name: "brand-sns-detail",
    component: () => import("@/views/brand-sns/detail/View.vue"),
    props: true,
  },
  {
    path: "/finace/receipt/list",
    name: "finace-receipt-list",
    component: () => import("@/views/finance/receipt/list/View.vue"),
  },
  {
    path: "/banner-adjust",
    name: "banner-adjust",
    component: () => import("@/views/banner-adjust/View.vue"),
  },
  {
    path: "/example/test",
    name: "test",
    component: () => import("@/views/test/View.vue"),
  },
  {
    path: "/app-widget/banner/list",
    name: "app-widget-banner-list",
    component: () => import("@/views/app-widget/banner/list/View.vue"),
  },
  {
    path: "/app-widget/banner/create",
    name: "app-widget-banner-create",
    component: () => import("@/views/app-widget/banner/create/View.vue"),
  },
  {
    path: "/app-widget/banner/detail/:idx",
    name: "app-widget-banner-detail",
    component: () => import("@/views/app-widget/banner/detail/View.vue"),
  },
  {
    path: "/app-widget/item-group/list",
    name: "app-widget-item-group-list",
    component: () => import("@/views/app-widget/item-group/list/View.vue"),
  },
  {
    path: "/app-widget/item-group/create",
    name: "app-widget-item-group-create",
    component: () => import("@/views/app-widget/item-group/create/View.vue"),
  },
  {
    path: "/app-widget/item-group/detail/:idx",
    name: "app-widget-item-group-detail",
    component: () => import("@/views/app-widget/item-group/detail/View.vue"),
  },
  {
    path: "/app-manage/widget/list",
    name: "app-manage-widget-list",
    component: () => import("@/views/app-widget/widget/list/View.vue"),
  },
  {
    path: "/app-manage/widget/create",
    name: "app-manage-widget-create",
    component: () => import("@/views/app-widget/widget/create/View.vue"),
  },
  {
    path: "/app-manage/widget/detail/:idx",
    name: "app-manage-widget-detail",
    component: () => import("@/views/app-widget/widget/detail/View.vue"),
  },
  {
    path: "/campaign/screenshot-job/list/",
    name: "screenshot-job-list",
    component: () => import("@/views/campaign/screenshot-job/list/View.vue"),
  },
  {
    path: "/campaign/screenshot-job/detail/:idx",
    name: "screenshot-job-detail",
    component: () => import("@/views/campaign/screenshot-job/detail/View.vue"),
  },
  {
    path: "/campaign/posting-ranking-log/list/",
    name: "posting-ranking-log-list",
    component: () => import("@/views/campaign/posting-ranking-log/list/View.vue"),
  },
  {
    path: "/campaign/posting-ranking-log/detail/:idx",
    name: "posting-ranking-log-detail",
    component: () => import("@/views/campaign/posting-ranking-log/detail/View.vue"),
  },
  {
    path: "/campaign/compt/hidden-create/",
    name: "campaign-compt-hidden-create",
    component: () => import("@/views/campaign/hidden/create/View.vue"),
  },
  {
    path: "/campaign/compt/hidden-detail/:idx",
    name: "campaign-compt-hidden-detail",
    component: () => import("@/views/campaign/hidden/detail/View.vue"),
  },
  {
    path: "/campaign/compt/active-detail/:idx",
    name: "campaign-compt-active-detail",
    component: () => import("@/views/campaign/compt/active-detail/View.vue"),
  },
  {
    path: "/lead/list/",
    name: "lead-list",
    component: () => import("@/views/lead/list/View.vue"),
  },
  {
    path: "/web-manage/notice/list/",
    name: "web-manage-notice-list",
    component: () => import("@/views/web-manage/notice/list/View.vue"),
  },
  {
    path: "/web-manage/notice/create/",
    name: "web-manage-notice-create",
    component: () => import("@/views/web-manage/notice/create/View.vue"),
  },
  {
    path: "/web-manage/notice/detail/:idx",
    name: "web-manage-notice-detail",
    component: () => import("@/views/web-manage/notice/detail/View.vue"),
  },
  {
    path: "/commerce/store-adj/list/",
    name: "commerce-store-adj-list",
    component: () => import("@/views/commerce/store-adj/list/View.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/dashboard/View.vue"),
  },
  {
    path: "/campaign/suggestion/list/",
    name: "campaign-suggestion-list",
    component: () => import("@/views/campaign/suggestion/list/View.vue"),
  },
  {
    path: "/commerce/product-re/create/",
    name: "commerce-product-re-create",
    component: () => import("@/views/commerce/product/create/View.vue"),
  },
  {
    path: "/member/adv/detail-re/",
    name: "member-adv-detail-re",
    component: () => import("@/views/member/advertiser/detail-re/View.vue"),
  },
  {
    path: "/message/notification/list/",
    name: "message-notification-list",
    component: () => import("@/views/message/notification/list/View.vue"),
  },
  {
    path: "/message/sms-queue/list/",
    name: "message-sms-queue-list",
    component: () => import("@/views/message/sms-queue/list/View.vue"),
  },
  {
    path: "/message/sms-result/list/",
    name: "message-sms-result-list",
    component: () => import("@/views/message/sms-result/list/View.vue"),
  },
  {
    path: "/template/list/v3/",
    name: "template-list-v3",
    component: () => import("@/views/template/list/v3/View.vue"),
  },
  {
    path: "/message/app-push/list/",
    name: "message-app-push-list",
    component: () => import("@/views/message/app-push/list/View.vue"),
  },
  {
    path: "/message/app-push/create/",
    name: "message-app-push-create",
    component: () => import("@/views/message/app-push/detail/View.vue"),
  },
  {
    path: "/message/app-push/detail/:idx",
    name: "message-app-push-detail",
    component: () => import("@/views/message/app-push/detail/View.vue"),
  },
  {
    path: "/member/inf-recommend/list/",
    name: "member-inf-recommend-list",
    component: () => import("@/views/member/inf-recommend/list/View.vue"),
  },
  {
    path: "/member/inf-recommend/create/",
    name: "member-inf-recommend-create",
    component: () => import("@/views/member/inf-recommend/detail/View.vue"),
  },
  {
    path: "/member/inf-recommend/detail/:idx",
    name: "member-inf-recommend-detail",
    component: () => import("@/views/member/inf-recommend/detail/View.vue"),
  },
  {
    path: "/member/inf-type/list/",
    name: "member-inf-type-list",
    component: () => import("@/views/member/inf-type/list/View.vue"),
  },
  {
    path: "/labeling/create",
    name: "labeling-create",
    component: () => import("@/views/labeling/detail/View.vue"),
  },
  {
    path: "/commerce/order-group/detail/:idx",
    name: "commerce-order-group-detail",
    component: () => import("@/views/commerce/order-group/detail/View.vue"),
  },
  {
    path: "/campaign/expg/detail/:idx",
    name: "campaign-expg-detail",
    component: () => import("@/views/campaign/expg/detail/View.vue"),
  },
  {
    path: "/app-manage/widget-re/list/",
    name: "app-manage-widget-re-list",
    component: () => import("@/views/app-widget/widget-re/list/View.vue"),
  },
  {
    path: "/app-manage/banner-re/detail/:idx",
    name: "app-manage-banner-re-detail",
    component: () => import("@/views/app-widget/widget-re/detail/BannerView.vue"),
  },
  {
    path: "/app-manage/recent-product/detail/:idx",
    name: "app-manage-recent-product-detail",
    component: () => import("@/views/app-widget/widget-re/detail/RecentProductView.vue"),
  },
  {
    path: "/app-manage/fixed-campaign/detail/:idx",
    name: "app-manage-fixed-campaign-detail",
    component: () => import("@/views/app-widget/widget-re/detail/FixedCampaignView.vue"),
  },
  {
    path: "/app-manage/recommend-campaign/detail/:idx",
    name: "app-manage-recommend-campaign-detail",
    component: () => import("@/views/app-widget/widget-re/detail/RecommendCampaignView.vue"),
  },
  {
    path: "/app-manage/compt-curation/detail/:idx",
    name: "app-manage-compt-curation-detail",
    component: () => import("@/views/app-widget/widget-re/detail/ComptCurationView.vue"),
  },
  {
    path: "/app-manage/alert/detail/:idx",
    name: "app-manage-alert-detail",
    component: () => import("@/views/app-widget/widget-re/detail/AlertView.vue"),
  },
  {
    path: "/app-manage/promotion-modal/detail/:idx",
    name: "app-manage-promotion-modal-detail",
    component: () => import("@/views/app-widget/widget-re/detail/promotion-modal/View.vue"),
  },
  {
    path: "/campaign/screenshot-log/list/",
    name: "screenshot-log-list",
    component: () => import("@/views/campaign/screenshot-log/list/View.vue"),
  },
  {
    path: "/campaign/screenshot-log/detail/:idx",
    name: "screenshot-log-detail",
    component: () => import("@/views/campaign/screenshot-log/detail/View.vue"),
  },
  {
    path: "/web-manage/update-content-re/list/",
    name: "update-content-re-list",
    component: () => import("@/views/web-manage/update-content-re/list/View.vue"),
  },
  {
    path: "/web-manage/update-content-re/detail/:idx",
    name: "update-content-re-detail",
    component: () => import("@/views/web-manage/update-content-re/detail/View.vue"),
  },
  {
    path: "/web-manage/update-content-re/create/",
    name: "update-content-re-create",
    component: () => import("@/views/web-manage/update-content-re/detail/View.vue"),
  },
  {
    path: "/campaign/expg/list/",
    name: "expg-list",
    component: () => import("@/views/campaign/expg/list/View.vue"),
  },
  {
    path: "/app-manage/landing-path/detail/:idx",
    name: "app-manage-landing-path-detail",
    component: () => import("@/views/app-widget/widget-re/detail/landing-path/View.vue"),
  },
  {
    path: "/tagby-link/suggestion/list/",
    name: "tagby-link-suggestion-list",
    component: () => import("@/views/tagby-link/suggestion/list/View.vue"),
  },
  {
    path: "/message/dm-group/list/",
    name: "message-dm-group-list",
    component: () => import("@/views/message/dm-group/list/View.vue"),
  },
  {
    path: "/message/dm-group/create/",
    name: "message-dm-group-create",
    component: () => import("@/views/message/dm-group/detail/View.vue"),
  },
  {
    path: "/message/dm-group/detail/:idx",
    name: "message-dm-group-detail",
    component: () => import("@/views/message/dm-group/detail/View.vue"),
  },
  ...campaign,
  {
    path: "/campaign-recommend-test",
    name: "campaign-recommend-test",
    component: () => import("@/views/campaign-recommend-test/detail/View.vue"),
  },
  ...commerce,
]
