export default [
  {
    path: "/campaign/simple-recruit/detail/:idx",
    name: "campaign-simple-recruit-detail",
    component: () => import("@/views/campaign/simple-recruit/detail/View.vue"),
  },
  {
    path: "/campaign/hidden/list/",
    name: "campaign-hidden-list",
    component: () => import("@/views/campaign/hidden/list/View.vue"),
  },
]
