import axios from '@axios'
import moment from 'moment'

const setAxiosHeader = accessToken => {
  // axios 헤더 설정
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
}

const deleteToken = () => {
  delete axios.defaults.headers.common.Authorization
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('userData')
}

const isLiveToken = expireDate => moment.utc().diff(expireDate) < 60

const reIssueAccessToken = async refreshToken => {
  try {
    // 액세스 토큰 재발급
    const response = await axios.post(
      `${process.env.VUE_APP_BACKEND_SERVER}/auth/token_reissue/`,
      {
        refresh_token: refreshToken,
      },
    )
    const accessToken = response.data.access_token

    // 로컬 스토리지 업데이트
    localStorage.setItem('accessToken', JSON.stringify(accessToken))

    return accessToken
  } catch (error) {
    // 토큰 삭제
    deleteToken()

    console.log(error)
    return null
  }
}

export default async function checkIsLogin() {
  /*
  APP에서 매번 재확인(create, updated)

  checkAccessToken
  1.액세스 토큰 있을 때
   1-1. 유효기간 남았을 때(axios 헤더 설정)
   1-2. 유효기간 종료 시 (accessToken 삭제)
    1-2-1. 리프레시 토큰 있을 때 (재발급, 헤더 설정)
    1-2-2. 리프레시 토큰 없을 때 (로그인 페이지로 이동)

  2.액세스 토큰 없을때
    2-1. 리프레시 토큰 있을 때 (재발급, 헤더 설정)
    2-2. 리프레시 토큰 없을 때 (로그인 페이지로 이동)
  */

  const jsonAccessTokenInfo = localStorage.getItem('accessToken')
  const jsonRefreshTokenInfo = localStorage.getItem('refreshToken')

  // 1.액세스 토큰 있을 때
  if (jsonAccessTokenInfo) {
    const accessTokenInfo = JSON.parse(jsonAccessTokenInfo)
    // 1-1. 유효기간 남았을 때(axios 헤더 설정)
    if (isLiveToken(accessTokenInfo.expires_at)) {
      setAxiosHeader(accessTokenInfo.token)
      return true
    }
    // 1-2. 유효기간 종료 시 (accessToken 삭제)

    // 1-2-1. 리프레시 토큰 있을 때 (재발급, 헤더 설정 )
    if (jsonRefreshTokenInfo) {
      const refreshTokenInfo = JSON.parse(jsonRefreshTokenInfo)
      const newAccessTokenInfo = await reIssueAccessToken(
        refreshTokenInfo.token,
      )
      // 리이슈 성공
      if (newAccessTokenInfo) {
        // 헤더 설정
        setAxiosHeader(newAccessTokenInfo.token)
        return true
      }
      // 리이슈 실패
      deleteToken()
      return false
    }
    // 1-2-2. 리프레시 토큰 없을 때 (로그인 페이지로 이동)

    deleteToken()
    return false
  }
  // 2.액세스 토큰 없을때

  // 2-1. 리프레시 토큰 있을 때 (재발급, 헤더 설정,dashboard 이동)
  if (jsonRefreshTokenInfo) {
    const refreshTokenInfo = JSON.parse(jsonRefreshTokenInfo)
    const newAccessTokenInfo = await reIssueAccessToken(
      refreshTokenInfo.token,
    )
    // 리이슈 성공
    if (newAccessTokenInfo) {
      // 헤더 설정
      setAxiosHeader(newAccessTokenInfo.token)
      return true
    }
    // 리이슈 실패
    deleteToken()
    return false
  }
  // 2-2. 리프레시 토큰 없을 때 (로그인 페이지로 이동)

  deleteToken()
  return false
}
