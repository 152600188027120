import checkIsLogin from '@/auth/token/checkIsLogin'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = async () => {
  const isLogin = await checkIsLogin()
  return isLogin
}

export const getUserData = () => {
  const userData = localStorage.getItem('userData')
  if (typeof userData === 'string') {
    return JSON.parse(userData)
  }
  return userData
}

export const parseUserData = userData => ({
  id: userData.idx,
  fullName: userData.name,
  username: userData.name,
  email: userData.email,
  role: userData.permission,
  ability: [
    {
      action: 'manage',
      subject: 'all',
    },
  ],
  extras: {
    eCommerceCartItemsCount: 5,
  },
})

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (['ADMIN', 'MANAGER', 'P_MANAGER', 'C_MANAGER'].includes(userRole)) return '/'
  // if (userRole === 'client') return { name: 'access-control' }
  if (userRole === 'LABELLER') return '/labeling/create'
  return { name: 'auth-login' }
}
