import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  invoices: [
    {
      idx: 4987,
      type: '비상시',
      title: '티젠 콤부차 라즈베리 홍보 캠페인',
      brand_name: '티젠',
      sns: 'instagram',
      start_at: '2021-11-16',
      selector: '2/13',
      step: '모집중',
      state: '활성',
      owner: '태그바이',
    },
    {
      idx: 4986,
      type: '상시',
      title: '에버랜드 홍보 캠페인',
      brand_name: '에버랜드',
      sns: 'instagram',
      start_at: '2021-11-16',
      selector: '2/13',
      step: '모집중',
      state: '활성',
      owner: '태그바이',
    },
    {
      idx: 4985,
      type: '비상시',
      title: '넷플릭스 지옥 시사회',
      brand_name: '넷플릭스',
      sns: 'instagram',
      start_at: '2021-11-16',
      selector: '2/13',
      step: '모집중',
      state: '활성',
      owner: '태그바이',
    },
    {
      idx: 4984,
      type: '비상시',
      title: '이터널스 영화 시사회',
      brand_name: '메가박스',
      sns: 'instagram',
      start_at: '2021-11-16',
      selector: '2/13',
      step: '모집중',
      state: '활성',
      owner: '태그바이',
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/tagby/campaign/list').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1, sortBy = 'idx', sortDesc = false, status = null } = config.params
  /* eslint-enable */

  const filteredData = data.invoices

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      invoices: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// GET: Return Single Invoice
// ------------------------------------------------
mock.onGet(/\/tagby\/campaign\/view\/\d+/).reply(config => {
  // Get event id from URL
  let invoiceId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  invoiceId = Number(invoiceId)

  const invoiceIndex = data.invoices.findIndex(e => e.id === invoiceId)
  const invoice = data.invoices[invoiceIndex]
  const responseData = {
    invoice,
    paymentDetails: {
      totalDue: '$12,110.55',
      bankName: 'American Bank',
      country: 'United States',
      iban: 'ETD95476213874685',
      swiftCode: 'BR91905',
    },
  }

  if (invoice) return [200, responseData]
  return [404]
})

// ------------------------------------------------
// GET: Return Clients
// ------------------------------------------------
mock.onGet('/apps/invoice/clients').reply(() => {
  const clients = data.invoices.map(invoice => invoice.client)
  return [200, clients.slice(0, 5)]
})
