import apps from './apps'
import dashboard from './dashboard'
import uiElements from './ui-elements'
import pages from './pages'
import chartsMaps from './charts-maps'
import formsTable from './forms-tables'
import others from './others'

export default [
  ...apps,
  ...dashboard,
  ...pages,
  ...chartsMaps,
  ...formsTable,
  ...uiElements,
  ...others,
]
