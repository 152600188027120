import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  invoices: [
    {
      idx: 4988,
      store_name: '초능이네',
      store_idx: 248,
      orderer_type: 'SHP',
      orderer_name: '손초능',
      order_no: 'ORDERGROUP20211116152156F58N',
      total_amount: 29330,
      shipping_fee: 3000,
      pay_type: 'Card',
      state: 'Paid',
      created_at: '2021-11-25 12:44:55',
    },
    {
      idx: 4988,
      store_name: '초능이네',
      store_idx: 248,
      orderer_type: 'SHP',
      orderer_name: '손초능',
      order_no: 'ORDERGROUP20211116152156F58N',
      total_amount: 29330,
      shipping_fee: 3000,
      pay_type: 'Card',
      state: 'Paid',
      created_at: '2021-11-25 12:44:55',
    },
    {
      idx: 4988,
      store_name: '초능이네',
      store_idx: 248,
      orderer_type: 'SHP',
      orderer_name: '손초능',
      order_no: 'ORDERGROUP20211116152156F58N',
      total_amount: 29330,
      shipping_fee: 3000,
      pay_type: 'Card',
      state: 'Paid',
      created_at: '2021-11-25 12:44:55',
    },
    {
      idx: 4988,
      store_name: '초능이네',
      store_idx: 248,
      orderer_type: 'SHP',
      orderer_name: '손초능',
      order_no: 'ORDERGROUP20211116152156F58N',
      total_amount: 29330,
      shipping_fee: 3000,
      pay_type: 'Card',
      state: 'Paid',
      created_at: '2021-11-25 12:44:55',
    },
  ],
}

const orderData = {
  order: {
    "idx": 1553,
    "order_no": "ORDER20220221172543KA53",
    "order_group_idx": 1099,
    "product_idx": 2498,
    "product_thumb_img": "https://tagbyimg.s3.ap-northeast-2.amazonaws.com/commerce_user/product/63029/gs_img_1.jpg",
    "product_name": "비프리 냉동실 정리용기세트",
    "product_json": "{",
    "option_json": "{",
    "option_text": "[{",
    "qty": 1,
    "inf_fee": 12525,
    "vendor_fee": 39100,
    "amount": 54455,
    "vat": 5445,
    "state": "PAID",
    "reason": null,
    "inf_settle_state": "WAIT",
    "vendor_settle_state": "WAIT",
    "created_at": "2022-02-21 17:25:43",
    "updated_at": "2022-02-21 17:25:43"
  },
  order_group: [
    {
      "idx": 1099,
      "member_type": "SHP",
      "member_idx": 1587,
      "myshop_idx": 377,
      "store": '꼬송이네',
      "store_idx": 232,
      "name": '배동욱',
      "phone": '010-4767-3795',
      "is_gift": 0,
      "order_no": "ORDERGROUP20220221172543V5FF",
      "shipping_fee": 3000,
      "inf_fee": 12525,
      "vendor_fee": 42100,
      "total_amount": 57455,
      "vat": 5445,
      "card_fee": null,
      "state": "PAID",
      "is_deleted": 0,
      "created_at": "2022-02-21 17:25:43",
      "updated_at": "2022-02-21 17:26:09"
    },
  ],
  delivery: {
    "idx": 1537,
    "type": "ORDER",
    "type_idx": 1553,
    "shipment_company": null,
    "tracking_no": null,
    "zipcode": 18239,
    "address": "경기 화성시 수노을중앙로 293",
    "address_detail": "1007동 1901호 ",
    "recipient_name": "아베모에",
    "message": null,
    "phone": "01072405827",
    "state": "WAIT",
    "tracking_no_fail": 0,
    "start_at": null,
    "created_at": "2022-02-21 17:25:43",
    "updated_at": "2022-02-21 17:25:43"
  },
  charge: [
    {
      "idx": 4767,
      "member_type": "SHP",
      "member_idx": 1587,
      "charge_type": "ORDER",
      "type_idx": 1553,
      "amount": 54455,
      "vat": 5445,
      "title": "비프리 냉동실 정리용기세트",
      "description": "세트:20p",
      "charged_by": "SYSTEM",
      "state": "DONE",
      "created_at": "2022-02-21 17:26:09",
      "updated_at": "2022-02-21 17:26:09"
    },
    {
      "idx": 4768,
      "member_type": "SHP",
      "member_idx": 1587,
      "charge_type": "ORDER_SHIPPING",
      "type_idx": 1099,
      "amount": 3000,
      "vat": 0,
      "title": "배송비",
      "description": "",
      "charged_by": "SYSTEM",
      "state": "DONE",
      "created_at": "2022-02-21 17:26:09",
      "updated_at": "2022-02-21 17:26:09"
    }
  ],
  pay: [
    {
      "idx": 3018,
      "member_type": "SHP",
      "member_idx": 1587,
      "pay_type": "NICEPAY_AUTH",
      "target_type": "ORDER",
      "target_idx": 1099,
      "target_signature": "ORDERGROUP20220221172543V5FF",
      "charge_idx": null,
      "billing_idx": null,
      "pid": "tagbycom1m01012202220226062014",
      "amount": 61013,
      "vat": 1887,
      "card_quota": "00",
      "description": "비프리 냉동실 정리용기세트 상품 구매",
      "depositor_name": null,
      "cash_receipt_type": null,
      "cash_receipt_id_type": null,
      "cash_receipt_id": null,
      "state": "SUCCESS",
      "created_at": "2022-02-21 17:26:09",
    }
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/tagby/order/list').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1, sortBy = 'idx', sortDesc = false, status = null } = config.params
  /* eslint-enable */

  const filteredData = data.invoices

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      invoices: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// GET: Return Single Invoice
// ------------------------------------------------
mock.onGet(/\/tagby\/order\/view\/\d+/).reply(config => {
  // Get event id from URL
  // let orderIdx = config.url.substring(config.url.lastIndexOf('/') + 1)
  //
  // // Convert Id to number
  // orderIdx = Number(orderIdx)
  //
  // const dataIndex = data.invoices.findIndex(e => e.idx === orderIdx)
  // const invoice = data.invoices[dataIndex]

  // const responseData = {
  //   orderData
  // }

  if (orderData) return [200, orderData]
  return [404]
})

// ------------------------------------------------
// GET: Return Clients
// ------------------------------------------------
mock.onGet('/apps/invoice/clients').reply(() => {
  const clients = data.invoices.map(invoice => invoice.client)
  return [200, clients.slice(0, 5)]
})
